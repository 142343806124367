<template>
	<ul class="fq-list">
		<li class="fq-list__item" v-for="(question, index) in questions" :key="index" :class="{'active': question.showAnswer}">
			<div class="fq-question" @click="toggle(index)">
				{{ question.question }}
			</div>
			<div class="fq-answer" >
				<p v-markdown:text>{{ question.answer }}</p>
			</div>	
		</li>
	</ul>
</template>

<script>
	export default {
		props: {
			items: {
				type: Array,
				default: () => []
			},
			expand: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				questions: this.items.map(item => ({...item, showAnswer: false}))
			}
		},
		methods: {
			toggle(index) {
				this.questions[index].showAnswer = !this.questions[index].showAnswer;
			}
		},
		watch: {
			expand() {
				this.questions.forEach(question => question.showAnswer = this.expand);
			}
		}
	}
</script>

<style scoped>
.fq-list {
	margin-top: 1.66rem;
}

.mobile .fq-list__item {
	margin-bottom: .5rem;
}

.fq-question {
	position: relative;
	font-size: 1.0625rem;
	cursor: pointer;
	color: #05aabe;
	padding-right: 1.75em;
}

.mobile .fq-question {
	font-size: 1rem;
}

.fq-question::after {
	position: absolute;
	content: '+';
	top: 0;
	right: 0;
	font-size: 1.25em;
}

.fq-question:hover,
.fq-list__item.active .fq-question {
	color: var(--primary-color);
}

.fq-answer {
	margin: 0;
	padding: .33rem .5rem;
	color: initial;
	font-size: 1rem;
	line-height: 1.75;
	overflow: hidden;
	opacity: 0;
	max-height: 0;
	transition: all .3s ease-out;
}

.fq-list__item.active .fq-answer {
	opacity: .66;
	max-height: 599px;
	transition: all .1s ease-out;
}

.fq-list__item.active .fq-question::after {
	content: '-';
}
</style>