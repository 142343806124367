<template>
	<div class="frequent-questions">
		<div class="view-header">
			<img class="view-header__image" src="@/assets/foo-faq.png">
			<h1 class="title">Preguntas Frecuentes</h1>
			<p class="subtitle view-header__subtitle">
				Tenemos las respuestas para las preguntas más comunes sobre zione.sport
			</p>	
		</div>
		<div class="fq page-section" :class="$responsive">
			<div class="fq-topic" v-for="(topic, topicIndex) in topics" :key="topicIndex">
				<div class="fq-topic__head">
					<h3 class="topic-title">
						{{ topic.title }}
					</h3>
					<a class="topic__expand" href="#" @click.prevent="expand(topicIndex)">
						<span v-show="!topic.expand">Ver todo</span>
						<span v-show="topic.expand">Ocultar</span>
					</a>
				</div>
				<question-list :items="topic.items" :expand="topic.expand"/>
			</div>
		</div>
		<div class="fq-contac">
			<h2 class="title">
				¿Tienes más preguntas?
			</h2>
			<router-link class="lnk" :to="{ name: 'contactUs' }">Contáctanos </router-link>
			para que podamos ayudarte.
		</div>
	</div>
</template>

<script>
	import content from "@/content.json";
	import questionList from "@/components/QuestionList.vue";
	export default {
		components: { questionList },
		data() {
			return {
				topics: content.frequentQuestions.map(topic => ({...topic, expand: false}))
			}
		},
		methods: {
			expand(topic) {
				this.topics[topic].expand = !this.topics[topic].expand;
			}
		}
	}
</script>

<style scoped>

.fq {
	text-align: left;
}

.fq-topic {
	padding-bottom: 2rem;
}

.fq-topic__head {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-top: 1px solid #e0e0e0;
	padding-top: 1.66rem;
}

.topic__expand {
	flex-basis: 10%;
	display: inline-block;
	margin: .5em 0 0 auto; 
	font-family: arial, sans-serfi;
	font-size: .9em;
	color: var(--secondary-color);
	text-decoration: none;
	text-align: right;
}	

.mobile .topic__expand {
	flex-basis: 25%;
}

.topic-title {
	margin: 0;
	font-size: 1.5rem;
	font-weight: 400;
}

.topic-title__icon {
	font-size: .5rem;
}

.fq-contac {
	margin-top: 6.25rem;
	font-size: .9375rem;
}

.fq-contac .title {
	font-size: 1.375rem;
	margin-bottom: 1rem;
	font-weight: 400;
}

</style>